/* stylelint-disable no-invalid-position-at-import-rule */
/* stylelint-disable import-notation */
@import "./swiper.scss";

/* Removes weird yellow highlighting when focusing */
:focus {
	outline: none;
}

ion-button.no-transform {
	text-transform: none;
}

h1 {
	overflow: hidden;
	margin-top: 0;
	margin-right: 320px;
	color: var(--ion-color-primary);
	font-size: 48px;
	font-weight: 800;
	line-height: 48px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

h2 {
	margin-top: 3rem;
	margin-bottom: 0.5rem;
	color: var(--ion-color-primary);
	font-size: 1.25rem;
}

h3 {
	margin-top: 5px;
	margin-right: 320px;
	font-size: 15px;
	font-weight: 400;
}

small {
	font-size: 0.75em;
}

ion-card {
	border-radius: 7px;
	margin-bottom: 25px;
	box-shadow: var(--box-shadow-default);

	&.warning {
		animation: box-shadow-warning-animation 2.5s linear infinite;
	}
}

app-grouping {
	textarea {
		font-size: 12px !important;
	}
}

.question.ng-invalid app-question-template {
	ion-card {
		border-left-color: var(--ion-color-danger);
	}
}

ion-modal {
	--border-radius: 14px !important;

	box-shadow: var(--box-shadow-default);

	/* Use cssClass auto-height to make modals the height of the content */
	&.auto-height {
		&.bottom {
			align-items: flex-end;
		}

		--height: auto;

		.ion-page {
			position: relative;
			display: block;
			contain: content;

			.inner-content {
				overflow: auto;
				max-height: 80vh;
				padding: 10px;
			}
		}
	}
}

ion-alert {
	.alert-wrapper {
		border-radius: 14px !important;
	}
}

ion-popover {
	--width: auto;
	--max-width: 80%;
}

ion-loading.changing-location {
	--max-width: 50% !important;
	--width: 50%;
}

@media (pointer: fine) {
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		background: #fff;
	}

	::-webkit-scrollbar-track:hover {
		background: #f7f7f7;
	}

	::-webkit-scrollbar-thumb {
		background: #ccc;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #888;
	}

	.inner-scroll {
		scrollbar-width: thin;
	}
}

/* Modals */
.checklist-modal::part(content) {
	width: 600px;
	height: 600px;
}

ion-modal#attendant-picker-modal {
	--width: fit-content;
	--min-width: 250px;
	--max-width: 90%;
	--height: fit-content;
}

.signature-modal::part(content) {
	width: 600px;
	height: 400px;
}

ion-modal.signature-modal {
	--box-shadow: 0 28px 48px rgb(0 0 0 / 40%) !important;
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.file-uploads-modal::part(content) {
	width: 600px;
	height: 400px;
}

ion-modal.file-uploads-modal {
	--box-shadow: 0 28px 48px rgb(0 0 0 / 40%) !important;
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.close-location-modal::part(content) {
	width: 360px;
}

.down-location-modal::part(content) {
	width: 600px;
}

.note-editor-modal::part(content) {
	width: 500px;
}

.statistics-modal::part(content) {
	width: 90%;
}

.number-grid-settings-modal::part(content) {
	width: 500px;
}

#operator-login-modal::part(content) {
	width: 256px;
	height: 350px;
}

#change-pin-modal::part(content) {
	width: 256px;
	height: 350px;
}

.missing-competencies-alert {
	--max-width: 500px;
	--width: 90%;
}

.maintenance-warning-modal {
	--width: 500px;
	--max-width: 500px;
}

.pdf-viewer-modal {
	--width: 85%;
	--height: 85%;
}

app-element-template {
	ul,
	ol {
		padding-left: 1vw;
		margin-left: 1em;
		list-style-position: outside;

		li.ql-indent-1 {
			margin-left: 1em;
		}

		li.ql-indent-2 {
			margin-left: 2em;
		}

		li.ql-indent-3 {
			margin-left: 3em;
		}

		li.ql-indent-4 {
			margin-left: 4em;
		}
	}

	ul {
		list-style-type: disc;
	}

	ol {
		li {
			counter-increment: list-0;
			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			font-weight: 300;
		}

		li::marker {
			content: counter(list-0, decimal) ". ";
		}

		li.ql-indent-1 {
			counter-increment: list-1;
			counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
		}

		li.ql-indent-1::marker {
			content: counter(list-1, lower-alpha) ". ";
		}

		li.ql-indent-2 {
			counter-increment: list-2;
			counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
		}

		li.ql-indent-2::marker {
			content: counter(list-2, lower-roman) ". ";
		}

		li.ql-indent-3 {
			counter-increment: list-3;
			counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
		}

		li.ql-indent-3::marker {
			content: counter(list-3, decimal) ". ";
		}

		li.ql-indent-4 {
			counter-increment: list-4;
			counter-reset: list-5 list-6 list-7 list-8 list-9;
		}

		li.ql-indent-4::marker {
			content: counter(list-4, lower-alpha) ". ";
		}
	}
}

@keyframes fade {
	50% {
		opacity: 0.2;
	}
}

@keyframes box-shadow-warning-animation {
	0%,
	100% {
		box-shadow: var(--box-shadow-warning);
	}

	50% {
		box-shadow: var(--box-shadow-default);
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	5% {
		transform: scale(1.1);
	}

	39% {
		transform: scale(0.85);
	}

	45% {
		transform: scale(1);
	}

	60% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(0.9);
	}
}

.animate-fade-and-pulse {
	animation:
		fade 1.75s linear infinite,
		pulse 1.75s linear infinite;
}

@import url("~@ionic/angular/css/core.css");
@import url("~@ionic/angular/css/normalize.css");
@import url("~@ionic/angular/css/structure.css");
@import url("~@ionic/angular/css/typography.css");
@import url("~@ionic/angular/css/display.css");

/* Optional CSS utils that can be commented out */
@import url("~@ionic/angular/css/padding.css");
@import url("~@ionic/angular/css/float-elements.css");
@import url("~@ionic/angular/css/text-alignment.css");
@import url("~@ionic/angular/css/text-transformation.css");
@import url("~@ionic/angular/css/flex-utils.css");
