/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable import-notation */
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";

.swiper {
	.swiper-button-next,
	.swiper-button-prev {
		color: var(--ion-color-primary);
	}

	.swiper-slide {
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		background: #fff;
		-webkit-box-align: center;
		-webkit-box-pack: center;
		-ms-flex-align: center;
		-ms-flex-pack: center;
		font-size: 18px;
		text-align: center;
	}

	.swiper-wrapper {
		margin-bottom: 35px;
	}
}
