/** RideOps Variables **/
:root {
	--ion-font-family: "Montserrat", sans-serif;
	--ion-backdrop-opacity: 0.3;
	--box-shadow-default: 0px 0px 15px 0 rgb(0 87 125 / 15%), 0px 25px 10px 0px rgb(0 87 125 / 3%);
	--box-shadow-warning: 0px 0px 15px 0 #d55f46, 0px 25px 10px 0px rgb(0 87 125 / 3%);

	/** Z-index Variables **/
	--app-z-index-settings-widget: 1;
	--app-z-index-popover: 3;
	--app-z-index-current-user: 4;
	--app-z-index-environment-bar: 5;

	/**
	Ionic CSS Variables
	http://ionicframework.com/docs/theming/
**/

	--ion-text-color: #000;
	--ion-text-color-rgb: 0, 0, 0;
	--ion-background-color: #fff;
	--ion-background-color-rgb: 255, 255, 255;
	--ion-item-background: #fff;
	--ion-card-background: #fff;
	--ion-color-primary: #074f57;
	--ion-color-primary-rgb: 7, 79, 87;
	--ion-color-primary-contrast: #fff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #06464d;
	--ion-color-primary-tint: #206168;
	--ion-color-secondary: #7daf9c;
	--ion-color-secondary-rgb: 125, 175, 156;
	--ion-color-secondary-contrast: #000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #6e9a89;
	--ion-color-secondary-tint: #8ab7a6;
	--ion-color-tertiary: #d89e00;
	--ion-color-tertiary-rgb: 216, 158, 0;
	--ion-color-tertiary-contrast: #fff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #be8b00;
	--ion-color-tertiary-tint: #dca81a;
	--ion-color-success: #00755b;
	--ion-color-success-rgb: 0, 117, 91;
	--ion-color-success-contrast: #fff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #006750;
	--ion-color-success-tint: #1a836b;
	--ion-color-warning: #f26c4f;
	--ion-color-warning-rgb: 242, 108, 79;
	--ion-color-warning-contrast: #fff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #d55f46;
	--ion-color-warning-tint: #f37b61;
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #fff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #fff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
	--ion-color-primary-step-50: #f3f6f7;
	--ion-color-primary-step-100: #e6edee;
	--ion-color-primary-step-200: #cddcdd;
	--ion-color-primary-step-150: #dae5e6;
	--ion-color-primary-step-250: #c1d3d5;
	--ion-color-primary-step-300: #b5cacd;
	--ion-color-primary-step-350: #a8c1c4;
	--ion-color-primary-step-400: #9cb9bc;
	--ion-color-primary-step-450: #8fb0b3;
	--ion-color-primary-step-500: #83a7ab;
	--ion-color-primary-step-550: #779ea3;
	--ion-color-primary-step-600: #6a959a;
	--ion-color-primary-step-650: #5e8d92;
	--ion-color-primary-step-700: #518489;
	--ion-color-primary-step-750: #457b81;
	--ion-color-primary-step-800: #397279;
	--ion-color-primary-step-850: #2c6970;
	--ion-color-primary-step-900: #206168;
	--ion-color-primary-step-950: #13585f;

	/* Typography */
	--app-font-family: var(--ion-font-family);
	--app-font-sm-size: 14px;
	--app-font-sm-line-height: 24px;
	--app-font-sm: var(--app-font-sm-size) / var(--app-font-sm-line-height) var(--app-font-family);

	/* Text colors */
	--app-color-text: var(--ion-text-color);
	--app-color-text-muted: var(--ion-color-medium-shade);
}
